import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!message) newErrors.message = "Message is required";
    if (!name) newErrors.name = "Name is required";

    // setErrorMessage(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert(
        "Thanks for contacting us with your comments and questions. We'll respond to you very soon."
      );
      setName("");
      setEmail("");
      setMessage("");
    }
  };
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        {/* left */}
        <div className="contactLeft">
          {/* left-top */}
          <div className="contactLeftTop">
            <div className="contactLeft1Title">
              <h2>CONTACT US</h2>
              <soan>
                Windmill Hill Fruits Ltd
                <br />
                Windmill Hill
                <br />
                Harewood End
                <br />
                Hereford
                <br />
                HR2 8JS
                <br />
                UK
              </soan>
            </div>
          </div>
          {/* left-mid */}
          <div className="contactLeftMid">
            <div>
              <p>Registration number: 891211</p>
            </div>
            <div>
              <p>
                Tel: <a href="#">01989 730229</a>
                <br />
                Tel: <a href="#">01989 730603</a>
                <br />
                Email: <a href="#">client@frozenfruit.life</a>
              </p>
            </div>
            <div>
              <p>
                <a href="#">What3Words - ///crew.appendix.reporters</a>
              </p>
            </div>
          </div>
          {/* left-bot */}
          <div className="contactLeftBottom">
            <div className="contactLeftBottomTitle">
              <h2>Directions</h2>
              <p>
                DO NOT FOLLOW SAT NAV – POSTCODE WILL TAKE YOU TO THE WRONG
                PLACE.
              </p>
              <p>
                <strong>FROM M50</strong> – At Junction 4 take the 1st exit onto
                the A40 (signposted Ross-on-Wye & Monmouth). Continue straight
                over the next roundabout to stay on the A40. At the next
                roundabout, ‘Wilton Roundabout’, take the 4th exit onto the A49
                (signposted Hereford / A49). Continue along the A49 for approx.
                5 miles. Once you have passed through the village of Harewood
                End, Windmill Hill will be seen on your left.
              </p>
              <p>
                <strong>FROM A449</strong>– Continue along A449 onto A40 towards
                Ross on Wye. At ‘Wilton Roundabout’ take the first exit
                signposted Hereford / A49. Continue along the A49 for approx. 5
                miles. Once you have passed through the village of Harewood End,
                Windmill Hill will be seen on your left.
              </p>
              <p>
                <strong>FROM HEREFORD</strong> – Head South on A49 towards Ross
                on Wye. Continue through village of Much Birch. Approximately
                1.5 miles after you leave the village of Much Birch you will see
                a sign for Harweood End on your left, and Windmill Hill on your
                right, before you enter the village of Harewood End. If you
                enter Harewood End village with the 40MPH zone you have gone too
                far!
              </p>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="contactRight">
          <div className="contactRightTxt">
            <h2>Write to us</h2>
            <p>
              If you have any questions please get in touch using the form
              below.
            </p>
            <div className="contactRightForm">
              <label>Name*</label>
              <br />
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errorMessage.name && (
                <p className="error">{errorMessage.name}</p>
              )}
              <br />
              <label>Email*</label>
              <br />
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorMessage.email && (
                <p className="error">{errorMessage.email}</p>
              )}
              <br />
              <label>Mobile Number</label>
              <br />
              <input type="text" />
              <br />
              <label>Your Message*</label>
              <br />
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {errorMessage.message && (
                <p className="error">{errorMessage.message}</p>
              )}
              <br />
              <button onClick={handlePlaceOrder}>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
