import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="PrivacyDiv">
        <h1>Terms and Conditions</h1>
        <h2>BACKGROUND:</h2>
        <p>
          These Terms and Conditions, together with any and all other documents
          referred to herein, set out the terms of use under which you may use
          this website, www.britishfrozenfruits.co.uk (“Our Site”). Please read
          these Terms and Conditions carefully and ensure that you understand
          them. Your agreement to comply with and be bound by these Terms and
          Conditions is deemed to occur upon your first use of Our Site. If you
          do not agree to comply with and be bound by these Terms and
          Conditions, you must stop using Our Site immediately.
        </p>
        <ul className="ql">
          <li>1. Definitions and Interpretation</li>
          <li>2. Information About Us</li>
          <li>3. Access to Our Site</li>
          <li>4. Intellectual Property Rights</li>
          <li>5. Links to Our Site</li>
          <li>6. Links to Other Sites</li>
          <li>7. Disclaimers</li>
          <li>8. Our Liability</li>
          <li>9. Viruses, Malware and Security</li>
          <li>10. Acceptable Usage Policy</li>
          <li>11. Privacy and Cookies</li>
          <li>12. Changes to these Terms and Conditions</li>
          <li>13. Contacting Us</li>
          <li>14. Data Protection</li>
          <li>15. Law and Jurisdiction</li>
        </ul>
        <h2>1.Definitions and Interpretation</h2>
        <p>
          1.In these Terms and Conditions, unless the context otherwise
          requires, the following expressions have the following meanings:
        </p>
        <div className="privacy-table-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>“Content”</strong>
                </td>
                <td>
                  means any and all text, images, audio, video, scripts, code,
                  software, databases and any other form of information capable
                  of being stored on a computer that appears on, or forms part
                  of, Our Site; and
                </td>
              </tr>
              <tr>
                <td>
                  <strong>“We/Us/Our”</strong>
                </td>
                <td>
                  means Windmill Hill Fruits Ltd, a limited company registered
                  in England under company number 8901211, whose registered
                  address is Windmill Hill, Harewood End, Hereford, HR2 8JS, and
                  whose main trading address is Windmill Hill, Harewood End,
                  Hereford, HR2 8JS.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>2.Information About Us</h2>
        <dd>
          <ol>
            <li>
              Our Site, <a href="#">www.britishfrozenfruits.co.uk</a> , is owned
              and operated by Windmill Hill Fruits Ltd, a limited company
              registered in England under 8901211, whose registered address is
              Windmill Hill, Harewood End, Hereford, HR2 8JS and whose main
              trading address is Windmill Hill, Harewood End, Hereford, HR2 8JS.
              Our VAT number is GB195 9734 45.
            </li>
          </ol>
        </dd>
        <h2>3.Access to Our Site</h2>
        <dd>
          <ol>
            <li>Access to Our Site is free of charge.</li>
            <li>
              It is your responsibility to make any and all arrangements
              necessary in order to access Our Site.
            </li>
            <li>
              Access to Our Site is provided “as is” and on an “as available”
              basis. We may alter, suspend or discontinue Our Site (or any part
              of it) at any time and without notice. We will not be liable to
              you in any way if Our Site (or any part of it) is unavailable at
              any time and for any period.
            </li>
          </ol>
        </dd>

        <h2>4.Intellectual Property Rights</h2>
        <dd>
          <ol></ol>
        </dd>
        <ol>
          <li>
            All Content included on Our Site and the copyright and other
            intellectual property rights subsisting in that Content, unless
            specifically labelled otherwise, belongs to or has been licensed by
            Us. All Content is protected by applicable United Kingdom and
            international intellectual property laws and treaties.
          </li>
          <li>
            Subject to sub-Clause 4.3 you may not reproduce, copy, distribute,
            sell, rent, sub-licence, store, or in any other manner re-use
            Content from Our Site unless given express written permission to do
            so by Us.
          </li>
          <li>You may:</li>
          <ol>
            <li>
              Access, view and use Our Site in a web browser (including any web
              browsing capability built into other types of software or app);{" "}
            </li>
            <li>Download Our Site (or any part of it) for caching;</li>
            <li>Print from Our Site;</li>
            <li>Download extracts from pages on Our Site; and</li>
            <li>Save pages from Our Site for later and/or offline viewing.</li>
          </ol>
          <li>
            Our status as the owner and author of the Content on Our Site (or
            that of identified licensors, as appropriate) must always be
            acknowledged.
          </li>
          <li>
            You may not use any Content saved or downloaded from Our Site for
            commercial purposes without first obtaining a licence from Us (or
            our licensors, as appropriate) to do so. This does not prohibit the
            normal access, viewing and use of Our Site for general information
            purposes whether by business users or consumers.
          </li>
        </ol>
        <h2>5.Links to Our Site</h2>
        <dd>
          <ol>
            <li>
              You may link to Our Site provided that:
              <ol>
                <li>You do so in a fair and legal manner;</li>
                <li>
                  You do not do so in a manner that suggests any form of
                  association, endorsement or approval on Our part where none
                  exists;
                </li>
                <li>
                  You do not use any logos or trade marks displayed on Our Site
                  without Our express written permission; and
                </li>
                <li>
                  You do not do so in a way that is calculated to damage Our
                  reputation or to take unfair advantage of it.
                </li>
              </ol>
            </li>
            <li>
              You may not link to any page other than the homepage of Our Site,
              www.britishfrozenfruits.co.uk. Deep-linking to other pages
              requires Our express written permission.
            </li>
            <li>
              Framing or embedding of Our Site on other websites is not
              permitted without Our express written permission. Please contact
              Us at client@frozenfruit.life for further information.
            </li>
            <li>
              You may not link to Our Site from any other site the main content
              of which contains material that:
              <ol>
                <li>is sexually explicit;</li>
                <li>
                  is obscene, deliberately offensive, hateful or otherwise
                  inflammatory;
                </li>
                <li>promotes violence;</li>
                <li>promotes or assists in any form of unlawful activity;</li>
                <li>
                  discriminates against, or is in any way defamatory of, any
                  person, group or class of persons, race, sex, religion,
                  nationality, disability, sexual orientation, or age;
                </li>
                <li>
                  is intended or is otherwise likely to threaten, harass, annoy,
                  alarm, inconvenience, upset, or embarrass another person;
                </li>
                <li>
                  is calculated or is otherwise likely to deceive another
                  person;
                </li>
                <li>
                  is intended or is otherwise likely to infringe (or to threaten
                  to infringe) another person’s privacy;
                </li>
                <li>
                  misleadingly impersonates any person or otherwise
                  misrepresents the identity or affiliation of a particular
                  person in a way that is calculated to deceive (obvious
                  parodies are not included in this definition provided that
                  they do not fall within any of the other provisions of this
                  sub-Clause 5.4);
                </li>
                <li>
                  implies any form of affiliation with Us where none exists;
                </li>
                <li>
                  infringes, or assists in the infringement of, the intellectual
                  property rights (including, but not limited to, copyright,
                  trade marks and database rights) of any other party; or
                </li>
                <li>
                  is made in breach of any legal duty owed to a third party
                  including, but not limited to, contractual duties and duties
                  of confidence.
                </li>
              </ol>
            </li>

            <li>
              The content restrictions in sub-Clause 5.4 do not apply to content
              submitted to sites by other users provided that the primary
              purpose of the site accords with the provisions of sub-Clause 5.4.
              You are not, for example, prohibited from posting links on
              general-purpose social networking sites merely because another
              user may post such content. You are, however, prohibited from
              posting links on websites which focus on or encourage the
              submission of such content from users.
            </li>
          </ol>
        </dd>
        <h2>6.Links to Other Sites</h2>
        <dd>
          <ol>
            <li>
              Links to other sites may be included on Our Site. Unless expressly
              stated, these sites are not under Our control. We neither assume
              nor accept responsibility or liability for the content of third
              party sites. The inclusion of a link to another site on Our Site
              is for information only and does not imply any endorsement of the
              sites themselves or of those in control of them.
            </li>
          </ol>
        </dd>
        <h2>7.Disclaimers</h2>
        <dd>
          <ol>
            <li>
              Nothing on Our Site constitutes advice on which you should rely.
              It is provided for general information purposes only. Professional
              or specialist advice should always be sought before taking any
              action.
            </li>
            <li>
              Insofar as is permitted by law, We make no representation,
              warranty, or guarantee that Our Site will meet your requirements,
              that it will not infringe the rights of third parties, that it
              will be compatible with all software and hardware, or that it will
              be secure.
            </li>
            <li>
              We make reasonable efforts to ensure that the Content on Our Site
              is complete, accurate, and up-to-date. We do not, however, make
              any representations, warranties or guarantees (whether express or
              implied) that the Content is complete, accurate, or up-to-date.
            </li>
          </ol>
        </dd>

        <h2>8.Our Liability</h2>
        <dd>
          <ol>
            <li>
              To the fullest extent permissible by law, We accept no liability
              to any user for any loss or damage, whether foreseeable or
              otherwise, in contract, tort (including negligence), for breach of
              statutory duty, or otherwise, arising out of or in connection with
              the use of (or inability to use) Our Site or the use of or
              reliance upon any Content included on Our Site.
            </li>
            <li>
              To the fullest extent permissible by law, We exclude all
              representations, warranties, and guarantees (whether express or
              implied) that may apply to Our Site or any Content included on Our
              Site.
            </li>
            <li>
              If you are a business user, We accept no liability for loss of
              profits, sales, business or revenue; loss of business opportunity,
              goodwill or reputation; loss of anticipated savings; business
              interruption; or for any indirect or consequential loss or damage.
            </li>
            <li>
              We exercise all reasonable skill and care to ensure that Our Site
              is free from viruses and other malware. We accept no liability for
              any loss or damage resulting from a virus or other malware, a
              distributed denial of service attack, or other harmful material or
              event that may adversely affect your hardware, software, data or
              other material that occurs as a result of your use of Our Site
              (including the downloading of any Content from it) or any other
              site referred to on Our Site.
            </li>
            <li>
              We neither assume nor accept responsibility or liability arising
              out of any disruption or non-availability of Our Site resulting
              from external causes including, but not limited to, ISP equipment
              failure, host equipment failure, communications network failure,
              natural events, acts of war, or legal restrictions and censorship.
            </li>
            <li>
              Nothing in these Terms and Conditions excludes or restricts Our
              liability for fraud or fraudulent misrepresentation, for death or
              personal injury resulting from negligence, or for any other forms
              of liability which cannot be excluded or restricted by law. For
              full details of consumers’ legal rights, including those relating
              to digital content, please contact your local Citizens’ Advice
              Bureau or Trading Standards Office.
            </li>
          </ol>
        </dd>
        <h2>9.Viruses, Malware and Security</h2>
        <dd>
          <ol>
            <li>
              We exercise all reasonable skill and care to ensure that Our Site
              is secure and free from viruses and other malware.
            </li>
            <li>
              You are responsible for protecting your hardware, software, data
              and other material from viruses, malware, and other internet
              security risks.
            </li>
            <li>
              You must not deliberately introduce viruses or other malware, or
              any other material which is malicious or technologically harmful
              either to or via Our Site.
            </li>
            <li>
              You must not attempt to gain unauthorised access to any part of
              Our Site, the server on which Our Site is stored, or any other
              server, computer, or database connected to Our Site.
            </li>
            <li>
              You must not attack Our Site by means of a denial of service
              attack, a distributed denial of service attack, or by any other
              means.
            </li>
            <li>
              By breaching the provisions of sub-Clauses 9.3 to 9.5 you may be
              committing a criminal offence under the Computer Misuse Act 1990.
              Any and all such breaches will be reported to the relevant law
              enforcement authorities and We will cooperate fully with those
              authorities by disclosing your identity to them. Your right to use
              Our Site will cease immediately in the event of such a breach.
            </li>
          </ol>
        </dd>

        <h2>10.Acceptable Usage Policy</h2>
        <dd>
          <ol>
            <li>
              You may only use Our Site in a manner that is lawful.
              Specifically:
              <ol>
                <li>
                  you must ensure that you comply fully with any and all local,
                  national or international laws and/or regulations;
                </li>
                <li>
                  you must not use Our Site in any way, or for any purpose, that
                  is unlawful or fraudulent;
                </li>
                <li>
                  you must not use Our Site to knowingly send, upload, or in any
                  other way transmit data that contains any form of virus or
                  other malware, or any other code designed to adversely affect
                  computer hardware, software, or data of any kind; and
                </li>
                <li>
                  you must not use Our Site in any way, or for any purpose, that
                  is intended to harm any person or persons in any way.
                </li>
              </ol>
            </li>
            <li>
              We reserve the right to suspend or terminate your access to Our
              Site if you materially breach the provisions of this Clause 10 or
              any of the other provisions of these Terms and Conditions.
              Specifically, We may take one or more of the following actions:
              <ol>
                <li>
                  suspend, whether temporarily or permanently, your right to
                  access Our Site;
                </li>
                <li>issue you with a written warning;</li>
                <li>
                  take legal proceedings against you for reimbursement of any
                  and all relevant costs on an indemnity basis resulting from
                  your breach;
                </li>
                <li>take further legal action against you as appropriate;</li>
                <li>
                  disclose such information to law enforcement authorities as
                  required or as We deem reasonably necessary; and/or
                </li>
                <li>
                  any other actions which We deem reasonably appropriate (and
                  lawful).
                </li>
              </ol>
            </li>
            <li>
              We hereby exclude any and all liability arising out of any actions
              (including, but not limited to those set out above) that We may
              take in response to breaches of these Terms and Conditions.
            </li>
          </ol>
        </dd>
        <h2>11.Privacy and Cookies</h2>
        <p>
          Use of Our Site is also governed by Our Cookie and Privacy Policies,
          available from <a href="/privacy">Privacy Policy.</a> These policies
          are incorporated into these Terms and Conditions by this reference.
        </p>
        <h2>12.Changes to these Terms and Conditions</h2>
        <dd>
          <ol>
            <li>
              We may alter these Terms and Conditions at any time. Any such
              changes will become binding on you upon your first use of Our Site
              after the changes have been implemented. You are therefore advised
              to check this page from time to time.
            </li>
            <li>
              In the event of any conflict between the current version of these
              Terms and Conditions and any previous version(s), the provisions
              current and in effect shall prevail unless it is expressly stated
              otherwise.
            </li>
          </ol>
        </dd>
        <h2>13.Contacting Us</h2>
        <ol>
          <li>
            To contact Us, please email Us at client@frozenfruit.life or using
            any of the methods provided on Our contact page at{" "}
            <a href="/contact">Contact Us.</a>
          </li>
        </ol>
        <h2>14.Data Protection</h2>
        <ol>
          <li>
            Any and all personal information that We may collect will be
            collected, used and held in accordance with the provisions of the
            Data Protection Act 1998 and your rights and Our obligations under
            that Act.
          </li>
          <li>
            We may use your personal information to:
            <ol>
              <li>Reply to any communications you send to Us;</li>
            </ol>
          </li>
        </ol>
        <h2>15.Law and Jurisdiction</h2>
        <ol>
          <li>
            These Terms and Conditions, and the relationship between you and Us
            (whether contractual or otherwise) shall be governed by, and
            construed in accordance with, English law.
          </li>{" "}
          <li>
            If you are a consumer, any disputes concerning these Terms and
            Conditions, the relationship between you and Us, or any matters
            arising therefrom or associated therewith (whether contractual or
            otherwise) shall be subject to the jurisdiction of the courts of
            England, Wales, Scotland, or Northern Ireland, as determined by your
            residency
          </li>{" "}
          <li>
            If you are a business, any disputes concerning these Terms and
            Conditions, the relationship between you and Us, or any matters
            arising therefrom or associated therewith (whether contractual or
            otherwise) shall be subject to the exclusive jurisdiction of the
            courts of England and Wales.
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
