import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Privacy() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    orderNumberOrPostcode: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const buttonStyle = {
    backgroundColor: isSubmitted ? "rgb(75, 182, 120)" : "#4384f5", // 根据isSubmitted状态设置背景颜色
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.orderNumberOrPostcode)
      errors.orderNumberOrPostcode = "Order Number/Postcode is required";
    if (!formData.message) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // 表单数据有效，可以提交表单
      console.log("Form data:", formData);
      setIsSubmitted(true);
      setFormData({
        name: "",
        email: "",
        orderNumberOrPostcode: "",
        message: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="PrivacyDiv">
        <h1>Privacy Policy</h1>
        <h2>BACKGROUND:</h2>
        <p>
          Windmill Hill Fruits Ltd understands that your privacy is important to
          you and that you care about how your personal data is used and shared
          online. We respect and value the privacy of everyone who visits this
          website,<a href="/">www.britishfrozenfruits.co.uk</a> (“Our Site”) and
          will only collect and use personal data in ways that are described
          here, and in a manner that is consistent with Our obligations and your
          rights under the law.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you
          understand it. If you do not accept and agree with this Privacy
          Policy, you must stop using Our Site immediately.
        </p>
        <ul className="ql">
          <li>Definitions and Interpretation</li>
          <li>Information About Us</li>
          <li>What Does This Policy Cover?</li>
          <li>What Data Do We Collect?</li>
          <li>How Do We Use Your Data?</li>
          <li>How and Where Do We Store Your Data?</li>
          <li>Do We Share Your Data?</li>
          <li>What Happens If Our Business Changes Hands?</li>
          <li>How Can You Control Your Data?</li>
          <li>Your Right to Withhold Information</li>
          <li>How Can You Access Your Data?</li>
          <li>Our Use of Cookies</li>
          <li>Contacting Us</li>
          <li>Changes to Our Privacy Policy</li>
        </ul>
        <h2>Definitions and Interpretation</h2>
        <p>
          In this Policy, the following terms shall have the following meanings:
        </p>
        <div className="privacy-table-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Account</strong>
                </td>
                <td>
                  means an account required to access and/or use certain areas
                  and features of Our Site;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>“Cookie”</strong>
                </td>
                <td>
                  means a small text file placed on your computer or device by
                  Our Site when you visit certain parts of Our Site and/or when
                  you use certain features of Our Site. Details of the Cookies
                  used by Our Site are set out in section 13, below;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>"personal data"</strong>
                </td>
                <td>
                  means any and all data that relates to an identifiable person
                  who can be directly or indirectly identified from that data.
                  In this case, it means personal data that you give to Us via
                  Our Site. This definition shall, where applicable, incorporate
                  the definitions provided in the [EU Regulation 2016/679 – the
                  General Data Protection Regulation (“GDPR”)]; and
                </td>
              </tr>
              <tr>
                <td>
                  <strong>"We/Us/Our"</strong>
                </td>
                <td>
                  means Windmill Hill Fruits Ltd, a limited company registered
                  in England under company number 8901211, whose registered
                  address is Windmill Hill, Harewood End, Hereford, HR2 8JS, and
                  whose main trading address is Windmill Hill, Harewood End,
                  Hereford, HR2 8JS.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>Information About Us</h2>
        <dd>
          <ol>
            <li>
              Our Site is owned and operated by Windmill Hill Fruits Ltd, a
              limited company registered in England under company number
              8901211, whose registered address is Windmill Hill, Harewood End,
              Hereford, HR2 8JS and whose main trading address is Windmill Hill,
              Harewood End, Hereford, HR2 8JS.
            </li>
            <li>Our VAT number is GB195 9734 45.</li>
          </ol>
        </dd>
        <h2>What Does This Policy Cover?</h2>
        <p>
          This Privacy Policy applies only to your use of Our Site. Our Site may
          contain links to other websites. Please note that We have no control
          over how your data is collected, stored, or used by other websites and
          We advise you to check the privacy policies of any such websites
          before providing any data to them.
        </p>
        <h2>What Data Do We Collect?</h2>
        <p>
          Depending upon your use of Our Site, We may collect some or all of the
          following personal and non-personal data (please also see the section
          on Our Use of Cookies and similar technologies and Our Cookie Policy):
        </p>
        <ol>
          <li>Name;</li>
          <li>Date of birth;</li>
          <li>Gender;</li>
          <li>Business/company name</li>
          <li>Job title;</li>
          <li>Profession;</li>
          <li>
            Contact information such as email addresses and telephone numbers;
          </li>
          <li>
            Demographic information such as post code, preferences, and
            interests;
          </li>
          <li>IP address;</li>
          <li>Web browser type and version;</li>
          <li>Operating system;</li>
          <li>
            a list of URLs starting with a referring site, your activity on Our
            Site, and the site you exit to;
          </li>
          <li>Other information entered relevant to customer orders</li>
        </ol>
        <dt>
          <ol>
            <li>NOTE: We do not store customer credit card details. </li>
          </ol>
        </dt>
        <h2>How Do We Use Your Data?</h2>
        <dd>
          <ol>
            <li>
              All personal data is processed and stored securely, for no longer
              than is necessary in light of the reason(s) for which it was first
              collected. We will comply with Our obligations and safeguard your
              rights under the [Data Protection Act 1998] OR [GDPR] at all
              times. For more details on security see the section on
              <a href="/">How and Where Do We Store Your Data?</a> , below.
            </li>
            <li>
              Our use of your personal data will always have a lawful basis,
              either because it is necessary for Our performance of a contract
              with you, because you have consented to Our use of your personal
              data (e.g. by subscribing to emails), or because it is in Our
              legitimate interests. Specifically, We may use your data for the
              following purposes:
              <ol>
                <li>Providing and managing your Account;</li>
                <li>Providing and managing your access to Our Site;</li>
                <li>
                  Personalising and tailoring your experience on Our Site;
                </li>
                <li>
                  Supplying Our products to you (please note that We require
                  your personal data in order to enter into a contract with
                  you);
                </li>
                <li>Personalising and tailoring Our products for you;</li>
                <li>Replying to emails from you;</li>
                <li>
                  Supplying you with emails that you have opted into (you may
                  unsubscribe or opt-out at any time by emailing
                  client@frozenfruit.life ).
                </li>
                <li>Market research;</li>
                <li>
                  Analysing your use of Our Site and gathering feedback to
                  enable Us to continually improve Our Site and your user
                  experience;
                </li>
                <li>Internal record keeping.</li>
              </ol>
            </li>
            With your permission and/or where permitted by law, We may also use
            your data for marketing purposes which may include contacting you by
            email AND/OR telephone AND/OR text message AND/OR post with
            information, news and offers on Our products. We will not, however,
            send you any unsolicited marketing or spam and will take all
            reasonable steps to ensure that We fully protect your rights and
            comply with Our obligations under the [Data Protection Act 1998] OR
            [GDPR] and the Privacy and Electronic Communications (EC Directive)
            Regulations 2003.
            <li>
              Third parties whose content appears on Our Site may use third
              party Cookies, as detailed below in section 13. Please refer to
              section 13 for more information on controlling Cookies. Please
              note that We do not control the activities of such third parties,
              nor the data they collect and use and advise you to check the
              privacy policies of any such third parties.
            </li>
            <li>
              You have the right to withdraw your consent to Us using your
              personal data at any time, and to request that We delete it.
            </li>
            <li>
              The personal data collected is kept on record unless specifically
              requested to be deleted.
            </li>
          </ol>
        </dd>
        <h2>How and Where Do We Store Your Data?</h2>
        <dd>
          <ol>
            <li>
              We only keep your personal data for as long as We need to in order
              to use it as described above in section 6, and/or for as long as
              We have your permission to keep it.
            </li>
            <li>
              Data security is very important to Us, and to protect your data We
              have taken suitable measures to safeguard and secure data
              collected through Our Site.
            </li>
          </ol>
        </dd>
        <h2>Do We Share Your Data?</h2>
        <dd>
          <ol>
            <li>
              We may sometimes contract with third parties to supply products
              and services to you on Our behalf. These may include payment
              processing, delivery of goods, search engine facilities,
              advertising, and marketing. In some cases, the third parties may
              require access to some or all of your data. Where any of your data
              is required for such a purpose, We will take all reasonable steps
              to ensure that your data will be handled safely, securely, and in
              accordance with your rights, Our obligations, and the obligations
              of the third party under the law.
            </li>
            <li>
              We may compile statistics about the use of Our Site including data
              on traffic, usage patterns, user numbers, sales, and other
              information. All such data will be anonymised and will not include
              any personally identifying data, or any anonymised data that can
              be combined with other data and used to identify you. We may from
              time to time share such data with third parties such as
              prospective investors, affiliates, partners, and advertisers. Data
              will only be shared and used within the bounds of the law.
            </li>
            <li>
              In certain circumstances, We may be legally required to share
              certain data held by Us, which may include your personal data, for
              example, where We are involved in legal proceedings, where We are
              complying with legal requirements, a court order, or a
              governmental authority.
            </li>
          </ol>
        </dd>

        <h2>What Happens If Our Business Changes Hands?</h2>
        <dd>
          <ol>
            <li>
              We may, from time to time, expand or reduce Our business and this
              may involve the sale and/or the transfer of control of all or part
              of Our business. Any personal data that you have provided will,
              where it is relevant to any part of Our business that is being
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this Privacy
              Policy, be permitted to use that data only for the same purposes
              for which it was originally collected by Us.
            </li>
            <li>
              In the event that any of your data is to be transferred in such a
              manner, you will not be contacted in advance and informed of the
              changes.
            </li>
          </ol>
        </dd>
        <h2>How Can You Control Your Data?</h2>
        <dd>
          <ol>
            <li>
              When you submit personal data via Our Site, you may be given
              options to restrict Our use of your data. In particular, We aim to
              give you strong controls on Our use of your data for direct
              marketing purposes (including the ability to opt-out of receiving
              emails from Us which you may do by unsubscribing using the links
              provided in Our emails and at the point of providing your
              details.)
            </li>
            <li>
              You may also wish to sign up to one or more of the preference
              services operating in the UK: The Telephone Preference Service
              (“the TPS”), the Corporate Telephone Preference Service (“the
              CTPS”), and the Mailing Preference Service (“the MPS”). These may
              help to prevent you receiving unsolicited marketing. Please note,
              however, that these services will not prevent you from receiving
              marketing communications that you have consented to receiving.
            </li>
          </ol>
        </dd>

        <h2>Your Right to Withhold Information</h2>
        <dd>
          <ol>
            <li>
              You may access certain areas of Our Site without providing any
              data at all. However, to use all features and functions available
              on Our Site you may be required to submit or allow for the
              collection of certain data.
            </li>
            <li>
              You may restrict Our use of Cookies. For more information, see the
              section on <a href="#">Our Use of Cookies</a> and
              <a href="#"> Our Cookie</a>
              Policy.
            </li>
          </ol>
        </dd>
        <h2>How Can You Access Your Data?</h2>
        <p>
          You have the right to ask for a copy of any of your personal data held
          by Us (where such data is held). Under the GDPR, no fee is payable and
          We will provide any and all information in response to your request
          free of charge. Please contact Us for more details at
          client@frozenfruit.life, or using the contact details below.
        </p>
        <h2>Our Use of Cookies</h2>
        <dd>
          <ol>
            <li>
              Our Site may place and access certain first party Cookies on your
              computer or device. First party Cookies are those placed directly
              by Us and are used only by Us. We use Cookies to facilitate and
              improve your experience of Our Site and to provide and improve Our
              product and service. We have carefully chosen these Cookies and
              have taken steps to ensure that your privacy and personal data is
              protected and respected at all times.
            </li>
            <li>
              By using Our Site you may also receive certain third party Cookies
              on your computer or device. Third party Cookies are those placed
              by websites, services, and/or parties other than Us. Third party
              Cookies are used on Our Site for anaylitics to helpus improve the
              site. For more details, please refer to section 6, above, and to
              section 13.6 below. These Cookies are not integral to the
              functioning of Our Site and your use and experience of Our Site
              will not be impaired by refusing consent to them.
            </li>
            <li>
              All Cookies used by and on Our Site are used in accordance with
              current Cookie Law.
            </li>
            <li>
              <p>
                The following first party Cookies may be placed on your computer
                or device:
              </p>
              <div className="privacy-table-container">
                <table>
                  <thead>
                    <tr>
                      <td>Name of Cookie</td>
                      <td>Purpose</td>
                      <td>Strictly Necessary</td>
                    </tr>
                    <tr>
                      <td>CART</td>
                      <td>
                        The association with the customer's shopping cart.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>CATEGORY_INFO</td>
                      <td>
                        Stores the category info on the page to load pages
                        faster.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>COMPARE</td>
                      <td>
                        The items in the customer's Compare Products list.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>CUSTOMER</td>
                      <td>
                        An encrypted version of the shopper's customer ID.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>CUSTOMER_AUTH</td>
                      <td>
                        Indicates if the customer are currently logged in to the
                        store.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>CUSTOMER_INFO</td>
                      <td>
                        An encrypted version of the shopper's customer group.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>EXTERNAL_NO_CACHE</td>
                      <td>Indicates if caching is disabled or enabled.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>FRONTEND</td>
                      <td>The customer's session ID.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>GUEST-VIEW</td>
                      <td>Determines if guests can edit their orders.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>LAST_CATEGORY</td>
                      <td>The last category visited by the shopper.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>LAST_PRODUCT</td>
                      <td>The most recent product viewed by the shopper.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>NEWMESSAGE</td>
                      <td>
                        Indicates whether a new message has been received.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>NO_CACHE</td>
                      <td>
                        Indicates if the cache can be used to store information.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>PERSISTENT_SHOPPING_CART</td>
                      <td>
                        A link to information about the shopper's cart and
                        viewing history.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>RECENTLYCOMPARED</td>
                      <td>Items recently compared by the shopper.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>STF</td>
                      <td>
                        Information on products the shopper has emailed to
                        friends.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>STORE</td>
                      <td>The store view or language chosen by the shopper.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>USER_ALLOWED_SAVE_COOKIE</td>
                      <td>
                        Indicates if the shopper allows cookies to be saved.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>VIEWED_PRODUCT_IDS</td>
                      <td>The products recently viewed by the shopper.</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>WISHLIST</td>
                      <td>
                        An encrypted list of products added to the shopper's
                        wishlist.
                      </td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>WISHLIST_CNT</td>
                      <td>The number of items in the shopper's wishlist.</td>
                      <td>Yes</td>
                    </tr>
                  </thead>
                </table>
              </div>
              <p>
                youtube may also place additional third-party cookies on your
                computer when you watch the videos on the site.
              </p>
            </li>
            <li>
              Our Site uses analytics services provided by google. Website
              analytics refers to a set of tools used to collect and analyse
              anonymous usage information, enabling Us to better understand how
              Our Site is used. This, in turn, enables Us to improve Our Site
              and the product and service offered through it. You do not have to
              allow Us to use these Cookies, however whilst Our use of them does
              not pose any risk to your privacy or your safe use of Our Site, it
              does enable Us to continually improve Our Site, making it a better
              and more useful experience for you.
            </li>
            <li>
              The analytics service(s) used by Our Site use(s) Cookies to gather
              the required information.
            </li>
            <li>
              <p>
                The analytics service(s) used by Our Site use(s) the following
                Cookies:
              </p>
              <div className="privacy-table-container">
                <table>
                  <thead>
                    <tr>
                      <td>Name of Cookie</td>
                      <td>Purpose</td>
                      <td>Strictly Necessary</td>
                    </tr>
                    <tr>
                      <td>__utma</td>
                      <td>
                        Used to distinguish users and sessions. The cookie is
                        created when the javascript library executes and no
                        existing __utma cookies exists. The cookie is updated
                        every time data is sent to Google Analytics.{" "}
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>__utmt</td>
                      <td>Used to throttle request rate.</td>
                      <td> No</td>
                    </tr>
                    <tr>
                      <td>__utmb</td>
                      <td>
                        Used to determine new sessions/visits. The cookie is
                        created when the javascript library executes and no
                        existing __utmb cookies exists. The cookie is updated
                        every time data is sent to Google Analytics.
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>__utmc</td>
                      <td>
                        Not used in ga.js. Set for interoperability with
                        urchin.js. Historically, this cookie operated in
                        conjunction with the __utmb cookie to determine whether
                        the user was in a new session/visit.
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>__utmz</td>
                      <td>
                        Stores the traffic source or campaign that explains how
                        the user reached your site. The cookie is created when
                        the javascript library executes and is updated every
                        time data is sent to Google Analytics.
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>__utmv</td>
                      <td>
                        Used to store visitor-level custom variable data. This
                        cookie is created when a developer uses the
                        _setCustomVar method with a visitor level custom
                        variable. This cookie was also used for the deprecated
                        _setVar method. The cookie is updated every time data is
                        sent to Google Analytics.
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>EXTERNAL_NO_CACHE</td>
                      <td>Indicates if caching is disabled or enabled.</td>
                      <td>NO</td>
                    </tr>
                  </thead>
                </table>
              </div>
            </li>
            <li>
              In addition to the controls that We provide, you can choose to
              enable or disable Cookies in your internet browser. Most internet
              browsers also enable you to choose whether you wish to disable all
              cookies or only third party Cookies. By default, most internet
              browsers accept Cookies but this can be changed. For further
              details, please consult the help menu in your internet browser or
              the documentation that came with your device.
            </li>
            <li>
              You can choose to delete Cookies on your computer or device at any
              time, however you may lose any information that enables you to
              access Our Site more quickly and efficiently including, but not
              limited to, login and personalisation settings.
            </li>
            <li>
              It is recommended that you keep your internet browser and
              operating system up-to-date and that you consult the help and
              guidance provided by the developer of your internet browser and
              manufacturer of your computer or device if you are unsure about
              adjusting your privacy settings.
            </li>
          </ol>
        </dd>
        <h2>Contacting Us</h2>
        <p>
          If you have any questions about Our Site or this Privacy Policy,
          please contact Us by email at client@frozenfruit.life, by telephone on
          01989 730229, or by post at Windmill Hill, Harewood End, Hereford, HR2
          8JS. Please ensure that your query is clear, particularly if it is a
          request for information about the data We hold about you (as above).
        </p>
        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We may change this Privacy Policy from time to time (for example, if
          the law changes). Any changes will be immediately posted on Our Site
          and you will be deemed to have accepted the terms of the Privacy
          Policy on your first use of Our Site following the alterations. We
          recommend that you check this page regularly to keep up-to-date.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
